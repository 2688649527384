import { title } from "assets/jss/material-kit-react.jsx";
import {
  grayColor,
  roseColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor
} from "assets/jss/material-kit-react.jsx";

const thisStyle = {
  section: {
    background: "#F9F9F9",
    padding: "70px 0px"
  },
  title: {
    ...title,
    marginBottom: "50px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  titleInfo: {
    ...title,
    marginBottom: "50px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    color: infoColor
  },
  titleSuccess: {
    ...title,
    marginBottom: "50px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    color: successColor
  },
  description: {
    color: "#999",
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "30px",
    marginLeft: "30px"
  },
  screen: {
    width: "300px",
    minWidth: "100%",
    height: "auto",
    marginLeft: "auto",
    marginRight: "auto"
  },
  logo: {
    display: "block",
    maxWidth: "200px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20%",
    marginBottom: "auto",
    top: "50%"
  }

};

export default thisStyle;
