import React, { useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";

// @material-ui/icons
import CopyIcon from "@material-ui/icons/AddCircle";

// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import thisStyle from "assets/jss/material-kit-react/views/commonSections/sectionStyle.jsx";
import Muted from "components/Typography/Muted.jsx";
import Success from "components/Typography/Success.jsx";
import YoroiDelegationScreen from "assets/img/yoroi-delegation.png";
import YoroiLogo from "assets/img/yoroi-logo.png";
import DaedalusLogo from "assets/img/daedalus-logo.png";
import CoinbaseLogo from "assets/img/coinbase-logo.png";
import CryptoComLogo from "assets/img/crypto-com-logo.png";

function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <input type="text" value={copyText} readOnly />
      {/* Bind our handler function to the onClick button property */}
      <Button onClick={handleCopyClick} color="danger" round>
        <CopyIcon/><span>{isCopied ? 'Copied!' : 'Copy'}</span>
      </Button>
    </div>
  );
}

class HowToCopy extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h2 className={classes.titleSuccess}>How To Delegate Your Cardano Stake</h2>
              <br />
              <div className={classes.textArea}>
                <Muted>
                  <h4 className={classes.titleSuccess}> 1. Setup A Wallet</h4>
                  <p>For Cardano, there are a few wallets out there. We recommend the <a href="https://yoroi-wallet.com/#/">Yoroi Wallet</a>. It's simple and is added as a browser extenstion or as an app for your mobile phone.</p> 
                  <p>The site has a great <a href="https://www.youtube.com/watch?v=DHtEgLMslIQ"> video tutorial</a> to help you set it up.</p>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <a href="https://yoroi-wallet.com/#/">
                      <img src={YoroiLogo} alt="..." className={classes.logo} />
                      </a>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <a href="https://daedaluswallet.io/">
                      <img src={DaedalusLogo} alt="..." className={classes.logo} />
                      </a>
                    </GridItem>
                  </GridContainer>
                  <h4 className={classes.titleSuccess}> 2. Fund the Wallet</h4>
                  <p>You need to purchase ADA coins from <a href="https://www.coinbase.com/">Coinbase</a> or <a href="https://crypto.com/">Crypto.com</a>. You need to setup an account and connect a financial institution to fund your account. Then transfer some ADA coins to your Wallet.</p>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <a href="https://www.coinbase.com/">
                      <img src={CoinbaseLogo} alt="..." className={classes.logo} />
                      </a>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <a href="https://crypto.com/">
                      <img src={CryptoComLogo} alt="..." className={classes.logo} />
                      </a>
                    </GridItem>
                  </GridContainer>
                  <h4 className={classes.titleSuccess}> 3. Select a Stake Pool</h4>
                  <p>In your Cardano Wallet, you should go to the "Delegation List" section, and search through the available stake pools for "THK" and then select "DELEGATE".</p>
                  <p><img src={YoroiDelegationScreen} alt="..." className={classes.screen} /></p>
                  <h4 className={classes.titleSuccess}> 4. Share in the Rewards</h4>
                  <p>Every 5 days, an "epoch", you can see the validation rewards added to your wallet! Simple, no actions needed. It's automatic.</p>
                </Muted>
              </div>
            </GridItem>
          </GridContainer>
{/*
          <Link to={"/"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Home Page
            </Button>
          </Link>
*/}
        </div>
      </div>
    );
  }
}

export default withStyles(thisStyle)(HowToCopy);
