import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";
// @material-ui/icons

// React icons
import { FaPlay } from 'react-icons/fa';

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import HowToStakeMainContent from "./Sections/HowToStakeMainContent";
import howToStakePageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

const dashboardRoutes = [];

class HowToStakePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      counter: 0 
    };
  }

  componentDidMount() {
    this.forceUpdate();
    console.log(".... forceUpdate")
    this.setState(state => ({ counter: state.counter+1 })); 
    console.log("State: ", this.state.open)
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="THK Pool"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/parallax-hakan-2.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>How To Stake Cardano</h1>
                <h4>
                  Delegate to our Stakepool.
                </h4>
                <br />
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>

          <HowToStakeMainContent />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(howToStakePageStyle)(HowToStakePage);
